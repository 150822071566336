import React, { useContext, useEffect, useState } from "react";
import axios from 'axios';
import { MyContext } from "../../../Mycontext";
import add from "../../../images/add.png";
import { MdOutlineCancel } from 'react-icons/md';
import { RxCross2 } from 'react-icons/rx';
import { AiOutlineFilePdf, AiOutlineFileText } from 'react-icons/ai';
import Selectant from '../../Jobs/Select';
import { message } from 'antd';
import EditPortfolio from "./EditPortfolio";
import { MdPictureAsPdf } from "react-icons/md";
import ModalViewDocument from "../../ModalViewDocument";

export default function AddPortfolio({ getPortfolioProfile }) {
    const { user, endPointUrl, Token } = useContext(MyContext);
    const [PF_Id, setPF_Id] = useState()
    const [skillSelected, setskillSelected] = useState([]);
    const [skillmaster, setskillmaster] = useState([]);
    const [preform, setPreform] = useState([]);
    const [PortData, setPortData] = useState([]);
    const [formList, setFormList] = useState([]);
    const [filedata, setFileData] = useState([]);
    const [showmodel, setshowModel] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [form, setForm] = useState({
        Title: "",
        Description: "",
        Type: "",
        Completion_Date: "",
        Skills: "",
        Project_Link: "",
    });
    const [errorForm, setErrorForm] = useState({
        Title: "",
        Description: "",
        Type: "",
        Completion_Date: "",
        Skills: "",
        Project_Link: "",
        preform: [],
    });
    // Use a useEffect hook to update the Skills field in the form state
    useEffect(() => {
        const skillsString = skillSelected.join(", ");
        setForm({ ...form, Skills: skillsString });
    }, [skillSelected]);
    const [File_Type, setFile_Type] = useState([])

    function getFileExtension(filename) {
        var a = filename.split(".");
        if (a.length === 1 || (a[0] === "" && a.length === 2)) {
            return "";
        }
        return a.pop();
    }

    const handlemodle = () => {
        setErrorForm({
            Title: "",
            Description: "",
            Type: "",
            Completion_Date: "",
            Skills: "",
            Project_Link: "",
            preform: "",
        })
        setshowModel(true)
    }

    const getPortfolio = async () => {
        const { data } = await axios.post(endPointUrl + "/user/getportfolioByUserID", {
            Token,
            UserID: user.id
        });
        if (data.data != null) {
            setPortData(data.data);
        }

    }

    const handleskillsmaster = async () => {
        const { data } = await axios.post(endPointUrl + "/Admin/getallskills", {
            Token,
        });
        let a = [];
        data.data.map((item) => a.push(item.Name));
        setskillmaster(a);
    };

    const handlefiles = () => {

        let arr = [];
        preform.map((file) => arr.push(getFileExtension(file.name)));
        setFile_Type(arr);
    }
    useEffect(() => {
        if (File_Type.length > 0) { uploadfiles() }
    }, [File_Type])

    const uploadfiles = async () => {

        const formdata = new FormData();
        preform.map((item) => formdata.append('File_Upload', item))

        try {
            const { data } = await axios.post(
                endPointUrl + "/user/docupload", formdata //Files array upload
            );

            try {
                await axios.post(endPointUrl + "/user/addportfolioDoc", {
                    Token,
                    PF_Id: PF_Id,
                    UserID: user.id,
                    File_Upload: data.filenames,
                    File_Type: File_Type
                });
                message.success("Portfolio Added")
                setPreform([]);
                getPortfolio();
            } catch {
                console.log("upload url produce error");
            }
        } catch {
            console.log("error occur during upload docs");
        }
    }

    const handleAddProtfolio = (value) => {
        const newFormErrors = {
            Title: "",
            Description: "",
            Type: "",
            Completion_Date: "",
            Skills: "",
            Project_Link: "",
            preform: "",
        };
        let showError = false;

        if (!form.Title && !form.Description && !form.Type && !form.Completion_Date && !form.Skills && formList.length >= 1 && filedata.length >= 1) {
            AddPortfolio(value, "Submit")
            setshowModel(false)
        }
        else {
            if (form.Title === "") {
                showError = true;
                newFormErrors.Title = 'Please fill out this field!';
            } else {
                newFormErrors.Title = '';
            }
            if (!form.Description || form.Description.trim() === "") {
                showError = true;
                newFormErrors.Description = 'Please fill out this field!';
              } else if (form.Description.length < 250) {
                showError = true;
                newFormErrors.Description = 'Description must be at least 250 characters!';
              } else if (form.Description.length > 1000) {
                showError = true;
                newFormErrors.Description = 'Description cannot exceed 1000 characters!';
              } else {
                newFormErrors.Description = '';
              }
            // if (form.Description === "") {
            //     showError = true;
            //     newFormErrors.Description = 'Please fill out this field!';
            // }
            // else if (form.Description.length < 250) {
            //     newFormErrors.Description = 'Description minimum 250 character allowed!';
            // }
            // else if (form.Description.length > 1000) {
            //     newFormErrors.Description = 'Description maximum 1000 character allowed!';
            // }
            // else {
            //     newFormErrors.Description = '';
            // }
            if (form.Type === "") {
                showError = true;
                newFormErrors.Type = 'Please fill out this field!';
            }
            else {
                newFormErrors.Type = "";
            }
            if (form.Completion_Date === "") {
                showError = true;
                newFormErrors.Completion_Date = 'Please fill out this field!';
            } else {
                newFormErrors.Completion_Date = '';
            }
            if (form.Skills === "") {
                showError = true;
                newFormErrors.Skills = 'Please fill out this field!';
            } else {
                newFormErrors.Skills = '';
            }
            // if (!preform || preform.length === 0) {
            //     showError = true;
            //     newFormErrors.preform = 'Please select a file!';
            //   } else {
            //     newFormErrors.preform = '';
            //   }
              
            // if (form.Project_Link === "") {
            //     showError = true;
            //     newFormErrors.Project_Link = 'Please fill out this field!';
            // } else {
            //     newFormErrors.Project_Link = '';
            // }
            setErrorForm(newFormErrors);
            if (!showError) {
                setFormList([...formList, form]);
                setFileData([...filedata, preform]);

                if (!value) {
                    setForm({
                        Title: "",
                        Description: "",
                        Type: "",
                        Completion_Date: "",
                        Skills: "",
                        Project_Link: "",
                    })

                    setskillSelected([])
                }

                if (value) {
                    AddPortfolio(value)
                    setshowModel(false)
                }
            }
        }
    }


    const AddPortfolio = async (value, type) => {
        try {
            var response = "";
            if (formList.length === 0 && form) {
                response = await axios.post(endPointUrl + "/user/addportfolio", {
                    formList: [form],
                    UserID: user.id,
                    Token
                });
            }
            else if (type === "Submit") {
                response = await axios.post(endPointUrl + "/user/addportfolio", {
                    formList,
                    UserID: user.id,
                    Token
                });
            }
            else {
                response = await axios.post(endPointUrl + "/user/addportfolio", {
                    formList: [...formList, form],
                    UserID: user.id,
                    Token
                });
            }


            const PortfolioIDs = response.data.data; // Assuming it's an array of Portfolio IDs
            // const formdata = new FormData();
            var imagaeData = "";
            if (filedata.length === 0 && preform.length > 0) {
                imagaeData = [preform]
            }
            else {
                imagaeData = [...filedata, preform];
            }


            imagaeData.forEach(async (images, index) => {
                const formdata = new FormData();
                images.forEach(image => {
                    formdata.append('File_Upload', image);
                });

                // Upload files for each portfolio
                try {
                    const response = await axios.post(
                        endPointUrl + "/user/portfoliodocupload",
                        formdata,
                        {
                            params: {
                                Token,
                                UserID: user.id,
                                prtID: PortfolioIDs[index] // Using the corresponding Portfolio ID
                            }
                        }
                    );
                    setForm({
                        Title: "",
                        Description: "",
                        Type: "",
                        Completion_Date: "",
                        Skills: "",
                        Project_Link: "",
                    })
                    getPortfolio();
                    setFormList([]);
                    setFileData([]);
                    setskillSelected([]);
                } catch (error) {
                    console.error("Error uploading documents:", error);
                }
            });
            message.success("Portfolio uploaded")
            getPortfolioProfile()
        } catch (error) {
            console.error("Error adding portfolio:", error);
            message.error("Unable to add portfolio");
        }

    }


    const handledeletefile = async (id) => {
        try {
            await axios.post(endPointUrl + "/user/deletePortfolioDoc", {
                Token,
                PFD_Id: id
            });
            getPortfolio();
        } catch {
            message.error("Unable to add portfolio")
        }
    }

    useEffect(() => {
        handleskillsmaster();
        getPortfolio();
    }, [EditPortfolio, PF_Id])

    return (
        <div className="education" style={{width:"70%"}}>
            <strong style={{ display: "flex", alignItems: "center" }}>
                Portfolio <img src={add} alt="" style={{ width: "20px" }} onClick={handlemodle} />
            </strong>

            <div style={{ marginTop: "3%" }}>
                {PortData.map((item, index) =>
                    item?.UserID ?
                        <div key={index}>
                            <div style={{ display: 'flex' }}>
                                {/* <div>
                                    {item.docs?.map((it, index) =>
                                        <div key={index} className="potfolioConatiner" style={{ position: "relative" }}>
                                            <a
                                                href={`${endPointUrl}/portfoliodoc/${it.File_Upload}`}
                                                rel="noopener noreferrer"
                                                title={it.File_Name}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setOpen(true);
                                                    setSelectedItem(it.File_Upload)
                                                }}
                                            >
                                                {it.File_Type === "png" ||
                                                    it.File_Type === "jpg" ||
                                                    it.File_Type === "jpeg" ||
                                                    it.File_Type === "gif" ||
                                                    it.File_Type === "webp" ||
                                                    it.File_Type === "jfif" ? (

                                                    <img
                                                        src={`${endPointUrl}/portfoliodoc/${it.File_Upload}`}
                                                        style={{ width: "120px", height: "140px", borderRadius: "10%" }}
                                                        alt={it.File_Type}
                                                        className="potfoliothumbail"
                                                    />
                                                ) : it.File_Type === "pdf" ? (
                                                    <MdPictureAsPdf className="potfoliothumbail" style={{ width: "50px", height: "50px" }} />
                                                ) : (
                                                    <AiOutlineFileText className="potfoliothumbail" style={{ width: "50px", height: "50px" }} />
                                                )}

                                            </a>
                                            {selectedItem && <ModalViewDocument open={open} setOpen={setOpen} selectedItem={selectedItem} setSelectedItem={setSelectedItem} type="portfoliodoc" />}
                                            <RxCross2
                                                className="deleteiconPotfolio"
                                                onClick={() => {
                                                    handledeletefile(it.PFD_Id)
                                                }}
                                            />

                                        </div>
                                    )
                                    }
                                </div> */}

                                <div style={{ marginLeft: '3%', color: "#606563" }}>
                                    <div><span style={{ color: "black", fontWeight: "bold" }}>{item?.Title} - {item?.Type}</span>
                                    </div>
                                    <div className="edusubtitle">
                                        {item?.Completion_Date ? new Date(item.Completion_Date).toLocaleDateString('en-GB') : ''}
                                    </div>
                                    <p className="edusubtitle">
                                        {item?.Description}
                                    </p>
                                    <div className="btn-popular skills" style={{ margin: 0 }}>
                                        {item.Skills?.length < 1 ? <button className="skill">.... ....</button> : null}
                                        {item.Skills?.split(',').map((it, index) => {
                                            if (index < 5) {
                                                return (
                                                    <button key={index} className="skill" style={{ fontSize: "8px", padding: "4px", gap: "3px" }}>
                                                        {it}
                                                    </button>
                                                );
                                            }
                                        })}
                                        {item.Skills?.split(',').length > 5 ? "+" : ""}
                                        {item.Skills?.split(',').length > 5 ? item.Skills?.split(',').length - 5 : ""}
                                    </div>

                                    {item?.Project_Link && <div style={{ fontSize: "12px", textDecoration: "underline" }}><a href={item?.Project_Link} target="_blank">Project Link</a></div>}
                                </div>
                            </div>
                            <div>
                                <EditPortfolio Portfolio={item} getPortfolio={getPortfolio} getPortfolioProfile={getPortfolioProfile} />
                            </div>
                            <hr style={{ borderTop: '1px solid rgb(228, 228, 228)' }}></hr>
                        </div> : null
                )}
            </div>
            <div id="myModal" className={showmodel ? "model-open" : "model"}>

                <div className="modal-content" style={{ padding: 0 }}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", background: "#ebebeb", padding: "0 40px", borderRadius: "20px 20px 0 0" }}>
                        <span style={{ color: "#46c294", fontWeight: "600" }}>Add Portfolio</span>
                        <span className="close" onClick={(() => setshowModel(false))}>
                            &times;
                        </span>
                    </div>
                    <div style={{ padding: "0 40px", margin: "30px 0" }}>
                        <form className="model-field exp-form"
                            encType="multipart/form-data"
                            onSubmit={(e) => {
                                e.preventDefault();
                            }}
                        >
                            <div style={{ paddingInlineEnd: "20px" }}>
                                <div>
                                    {" "}
                                    <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                                        <div>
                                            <label htmlFor="title">Portfolio Name<span className="asterisk">*</span></label>
                                            <small style={{ color: "#ec1c24" }}>{errorForm.Title}</small>
                                            <input
                                                style={{ marginInlineEnd: "12%" }}
                                                type="text"
                                                name="title"
                                                value={form.Title}
                                                className={errorForm.Title != "" ? "errorborder" : ""}
                                                placeholder='Enter title'
                                                onChange={(e) => {
                                                    const inputValue = e.target.value.slice(0, 45);
                                                    if (!inputValue.trim()) {
                                                        return setForm({ ...form, Title: "" });
                                                    }
                                                    setForm({ ...form, Title: inputValue });
                                                }}
                                            />
                                        </div>

                                        <div>
                                            <label htmlFor="Completion_Date">Date<span className="asterisk">*</span></label>
                                            <small style={{ color: "#ec1c24" }}>{errorForm.Completion_Date}</small>
                                            <input
                                                value={form.Completion_Date}
                                                type="date"
                                                className={errorForm.Completion_Date != "" ? "errorborder" : ""}
                                                name="Completion_Date"
                                                placeholder="Enter Completion_Date"
                                                min="1900"
                                                max="2099"
                                                onChange={(e) => {
                                                    const enteredDate = e.target.value;
                                                    const extractedYear = enteredDate.split("-")[0].slice(0, 4);
                                                    setForm({ ...form, Completion_Date: `${extractedYear}-${enteredDate.split("-")[1]}-${enteredDate.split("-")[2]}` });
                                                }}
                                            />
                                        </div>

                                    </div>

                                    <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                                        <div>
                                            <label htmlFor="project_type">Project Type<span className="asterisk">*</span></label>
                                            <small style={{ color: "#ec1c24" }}>{errorForm.Type}</small>
                                            <select
                                                style={{ marginInlineEnd: "12%", marginBottom: "0", width: "100%" }}
                                                name="project_type"
                                                value={form.Type}
                                                className={errorForm.Type != "" ? "errorborder" : ""}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    if (!inputValue.trim()) {
                                                        return setForm({ ...form, Type: "" });
                                                    }
                                                    setForm({ ...form, Type: inputValue });
                                                }}
                                            >
                                                <option value="">Project type</option>
                                                <option value="Website">Website</option>
                                                <option value="Mobile App">Mobile App</option>
                                                <option value="ERP">ERP</option>
                                                <option value="SPA">SPA</option>
                                            </select>
                                        </div>

                                        <div style={{ width: "105%" }}>
                                            <label htmlFor="SkillsRequired">Skills<span className="asterisk">*</span></label>
                                            <small style={{ color: "#ec1c24" }}>{errorForm.Skills}</small>
                                            <Selectant
                                                label={"Enter skills"}
                                                options={skillmaster}
                                                setselected={setskillSelected}
                                                error={errorForm.Skills != ""}
                                                selected={skillSelected}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ marginTop: "10px" }}>
                                        <div >
                                            <label htmlFor="descrption" style={{ display: "block" }}>Description<span className="asterisk">*</span></label>
                                            <small style={{ color: "#ec1c24" }}>{errorForm.Description}</small>
                                            <textarea
                                                style={{ width: "98%" }}
                                                name="descrption"
                                                id=""
                                                cols="30"
                                                rows="3"
                                                maxLength={500}
                                                value={form.Description}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    if (!inputValue.trim()) {
                                                        return setForm({ ...form, Description: "" });
                                                    }
                                                    setForm({ ...form, Description: inputValue });
                                                }}
                                                className={errorForm.Description != "" ? "errorborder" : ""}
                                                placeholder='Enter description'></textarea>
                                        </div>

                                        {/* img section */}
                                        <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                                            <div>
                                                <label htmlFor="file" style={{ display: "block" }}>Files<span className="asterisk"></span><span style={{ fontSize: "10px", fontWeight: 300, color: "#9b9b9b",marginLeft:"6px" }}>maximum size 500 kb and format(jpg/jpeg/png/pdf)</span></label>
                                                <small style={{ color: "#ec1c24" }}>{errorForm.preform}</small>
                                                <input
                                                    style={{ marginInlineEnd: "12%" }}
                                                    type="file"
                                                    name="File_Upload"
                                                    multiple
                                                    className={errorForm.preform != "" ? "errorborder" : ""}
                                                    onChange={(e) => {
                                                        const files = e.target.files;
                                                        const currentFile = e.target.files[0]
                                                        const allowedFileTypes = [
                                                            "image/jpeg",
                                                            "image/png",
                                                            "image/jpg",
                                                            "application/pdf",
                                                        ];

                                                        if ((currentFile.size / 1024) <= 500) {
                                                            if (files) {
                                                                const filteredFiles = Array.from(files).filter(file => (
                                                                    allowedFileTypes.includes(file.type) && file.size / (1024 * 1024) <= 10
                                                                ));

                                                                if (filteredFiles.length > 0) {
                                                                    setPreform(filteredFiles);
                                                                } else {
                                                                    setPreform([])
                                                                    e.target.value = null
                                                                    message.warning("Files format and size mismatch");
                                                                }
                                                            }
                                                        }
                                                        else {
                                                            e.target.value = ''; // Reset the input value
                                                            message.warning('File size exceeds 500 KB. Please upload a smaller file.')
                                                        }
                                                    }}
                                                />

                                            </div>
                                            <div>
                                                <label htmlFor="Project_Link">Project Link</label>
                                                <small style={{ color: "#ec1c24" }}>{errorForm.Project_Link}</small>
                                                <input
                                                    style={{ marginInlineEnd: "12%" }}
                                                    type="text"
                                                    name="Project_Link"
                                                    value={form.Project_Link}
                                                    className={errorForm.Project_Link != "" ? "errorborder" : ""}
                                                    placeholder='Enter link'
                                                    onChange={(e) => {
                                                        const inputValue = e.target.value;
                                                        if (!inputValue.trim()) {
                                                            return setForm({ ...form, Project_Link: "" });
                                                        }
                                                        setForm({ ...form, Project_Link: inputValue });
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        {/* Display selected files */}
                                        {/* <div>
                                            {preform && preform.length > 0 && (
                                                <div>
                                                    <h4>Selected Files:</h4>
                                                    <ul>
                                                        {Array.from(preform).map((file, index) => (
                                                            <li key={index} style={{ display: "grid", gridTemplateColumns: "30% 60%", alignItems: "center", gap: "30px" }}>
                                                                <span style={{ overflowWrap: "break-word" }}><strong>Type:</strong> {file.type}</span>
                                                                <span style={{ overflowWrap: "break-word" }}> <strong>Name:</strong> {file.name}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </div> */}
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                                    <input type="reset" className="btn" value={'Add Portfolio'} onClick={() => handleAddProtfolio(false)} style={{ width: "20%", backgroundColor: "#e3e3e3", color: "#000" }} />
                                    <input type="submit" className="btn" onClick={() => handleAddProtfolio(true)} value={"Submit"} />
                                </div>

                            </div>
                        </form>
                        <div style={{ maxHeight: '200px', overflowY: "scroll" }}>
                            {formList?.map((value, index) => (
                                <div key={index} style={{ padding: "0 40px", marginTop: "30px", marginBottom: "30px", display: 'flex', gap: '10px' }}>
                                    <span>
                                        <strong>Portfolio Name</strong>:
                                        {value.Title}
                                    </span>
                                    {/* <br /> */}
                                    <span>
                                        <strong>Description</strong>:
                                        {value.Description}
                                    </span>
                                    {/* <br /> */}
                                    <span>
                                        <strong>Type</strong>:
                                        {value.Type}
                                    </span>
                                    {/* <br /> */}
                                    <span>
                                        <strong>Completion_Date</strong>:
                                        {value.Completion_Date}
                                    </span>
                                    {/* <br /> */}
                                    <span>
                                        <strong>Skills</strong>:
                                        {value.Skills}
                                    </span>
                                    {/* <br /> */}
                                    <span>
                                        <strong>Project_Link</strong>:
                                        {value.Project_Link}
                                    </span>
                                    {/* <br /> */}
                                    <span>
                                        <strong>Files:</strong>
                                        {filedata[index].map((image, index) => (
                                            <span> <span key={index}>{image.name}</span> <br /></span>
                                        )
                                        )}
                                    </span>
                                    {/* <hr /> */}
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}