import React, { useState, useReducer, useEffect } from "react";
import io from 'socket.io-client';
const session = JSON.parse(localStorage.getItem("data") || "null");
let reducer = (user, newuser) => {
  if (newuser === null) {
    localStorage.removeItem("data");
    return initialState;
  }
  
  return { ...user, ...newuser };
};
const initialState = null;
const MyContext = React.createContext();

function MyProvider(props) {
  const [user, setuser] = useReducer(reducer, session || initialState);
  const [socket, setSocket] = useState(null);
  const [showvalue, setShowValue] = useState(false);
  const [platformRevnue, setPlatformRevenue] = useState(0);
  const [chatUsers, setChatUsers] = useState([]);
  const [notificationCount, setNewNotificationCount] = useState(0);
  const [notificationArticles, setNotificationArticles] = useState([]);
  const [UnreadUsers, setUnreadUsers] = useState(new Set());
  const [NewMessageCount, setNewMessageCount] = useState(0);
  const [onlineUser, setOnlineUser] = useState([]);
  const Token = "W4lyaidIGicUCR1gCtaC2JEbn4D2AvzyRwRAHCCcUjvWOx6Epe";

  const [currentPage, setCurrentPage] = useState(1);
  const [showMessg, setshowMessg] = useState(false);
  const [showchat, setShowchat] = useState(false);
  const [Ruser, setRUser] = useState(null);
  const [JobsId, setJobsId] = useState(null);
  const [earningType, setEarningType] = useState('');
  const [showSummary, setShowSummary] = useState(false);

  //const endPointUrl = "http://192.168.29.155:5001";
    const endPointUrl = "https://digilanxer.com:5000"; 
  useEffect(() => {
    localStorage.setItem("data", JSON.stringify(user));
  }, [user]);

  useEffect(() => (
  //  setSocket(io("http://192.168.29.155:5001"))
      setSocket(io('https://digilanxer.com:5000')) 
  ), [])

  return (
    <MyContext.Provider value={{
      user,
      setuser,
      Token,
      endPointUrl,
      notificationCount,
      setNewNotificationCount,
      NewMessageCount,
      setNewMessageCount,
      notificationArticles,
      setNotificationArticles,
      UnreadUsers,
      setUnreadUsers,
      showvalue,
      setShowValue,
      chatUsers,
      setChatUsers,
      onlineUser,
      setOnlineUser,
      socket,
      currentPage,
      setCurrentPage,
      showMessg,
      setshowMessg,
      showchat,
      setShowchat,
      Ruser,
      setRUser,
      platformRevnue,
      setPlatformRevenue,
      JobsId,
      setJobsId,
      earningType,
      setEarningType,
      showSummary,
      setShowSummary
    }}>
      {props.children}
    </MyContext.Provider>
  );
}
export { MyContext, MyProvider };